var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('div',{staticClass:"container-bg",staticStyle:{"width":"50vw","display":"block","margin":"auto","margin-right":"10px"}},[_c('div',{staticClass:"container-fg"},[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('img',{staticStyle:{"width":"130px","margin-bottom":"1%"},attrs:{"src":require("../../assets/logos/getac-logo.svg")}}),_c('div',{staticClass:"text-h7 nav-font pl-1",staticStyle:{"margin-top":"14px","font-weight":"400"}},[_vm._v("VIDEO PARTS ORDER")])]),_c('v-spacer'),_c('v-divider',{staticStyle:{"margin-top":"10px","margin-bottom":"10px","border-color":"gray"}}),_c('h1',{staticStyle:{"font-size":"36px","letter-spacing":"-0.08rem"}},[_vm._v(" Shipping Details ")]),_c('p',[_vm._v("Please confirm or fill out shipping details below.")]),_c('v-card',_vm._b({staticStyle:{"background-color":"transparent","padding":"0px"},attrs:{"dark":"","elevation":"0","disabled":false},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)}}},'v-card',_vm.$attrs,false),[_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
                                    _vm.formRules.maxLength(35, 'characters')
                                ],"dark":"","dense":"","label":"Company","outlined":""},model:{value:(_vm.userStoredContact.company),callback:function ($$v) {_vm.$set(_vm.userStoredContact, "company", $$v)},expression:"userStoredContact.company"}})],1)],1),_c('v-row',{staticStyle:{"margin-top":"-25px"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
                                    _vm.formRules.required,
                                    _vm.formRules.maxLength(35, 'characters')
                                ],"dark":"","dense":"","label":"First Name","outlined":""},model:{value:(_vm.userStoredContact.firstName),callback:function ($$v) {_vm.$set(_vm.userStoredContact, "firstName", $$v)},expression:"userStoredContact.firstName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
                                    _vm.formRules.required,
                                    _vm.formRules.maxLength(35, 'characters')
                                ],"dark":"","dense":"","label":"Last Name","outlined":""},model:{value:(_vm.userStoredContact.lastName),callback:function ($$v) {_vm.$set(_vm.userStoredContact, "lastName", $$v)},expression:"userStoredContact.lastName"}})],1)],1),_c('v-row',{staticStyle:{"margin-top":"-25px"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
                                    _vm.formRules.required,
                                    _vm.formRules.maxLength(35, 'characters')
                                ],"dense":"","dark":"","label":"Email","outlined":""},model:{value:(_vm.userStoredContact.email),callback:function ($$v) {_vm.$set(_vm.userStoredContact, "email", $$v)},expression:"userStoredContact.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[
                                    _vm.formRules.required,
                                    _vm.formRules.maxLength(25, 'characters')
                                ],"dense":"","dark":"","label":"Phone","outlined":""},model:{value:(_vm.userStoredContact.phone),callback:function ($$v) {_vm.$set(_vm.userStoredContact, "phone", $$v)},expression:"userStoredContact.phone"}})],1)],1),_c('v-card-text',{staticClass:"mt-0",staticStyle:{"padding":"0px"}},[_c('v-text-field',_vm._b({ref:"address-line1",attrs:{"rules":[
                                _vm.formRules.required,
                                _vm.formRules.maxLength(35, 'characters')
                            ],"dense":"","outlined":"","label":"Address Line 1"},model:{value:(_vm.userStoredAddress.line1),callback:function ($$v) {_vm.$set(_vm.userStoredAddress, "line1", $$v)},expression:"userStoredAddress.line1"}},'v-text-field',_vm.$attrs,false)),_c('v-text-field',_vm._b({ref:"address-line2",attrs:{"dense":"","outlined":"","label":"Address Line 2"},model:{value:(_vm.userStoredAddress.line2),callback:function ($$v) {_vm.$set(_vm.userStoredAddress, "line2", $$v)},expression:"userStoredAddress.line2"}},'v-text-field',_vm.$attrs,false)),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',_vm._b({ref:"country",attrs:{"items":_vm.countries,"dense":"","outlined":"","item-text":"name","item-value":"countryCode","label":"Country","placeholder":"Country"},on:{"change":_vm.countryChanged},model:{value:(_vm.userStoredAddress.country),callback:function ($$v) {_vm.$set(_vm.userStoredAddress, "country", $$v)},expression:"userStoredAddress.country"}},'v-autocomplete',_vm.$attrs,false))],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',_vm._b({ref:"state",attrs:{"item-text":"name","dense":"","outlined":"","item-value":"stateCode","loading":_vm.statesLoading,"disabled":_vm.statesLoading ||
                                        !_vm.userStoredAddress.country,"items":_vm.stateProvinces,"label":"State/Province/Region","placeholder":"State"},on:{"change":function($event){return _vm.stateSelected($event)}},model:{value:(_vm.userStoredAddress.state),callback:function ($$v) {_vm.$set(_vm.userStoredAddress, "state", $$v)},expression:"userStoredAddress.state"}},'v-autocomplete',_vm.$attrs,false))],1)],1),_c('v-row',{staticStyle:{"margin-top":"-25px"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.isApo)?_c('v-select',_vm._b({ref:"city",attrs:{"dense":"","outlined":"","rules":[
                                        _vm.formRules.required,
                                        _vm.formRules.maxLength(
                                            35,
                                            'characters'
                                        )
                                    ],"items":['APO', 'DPO', 'FPO'],"label":"City"},model:{value:(_vm.apoCity),callback:function ($$v) {_vm.apoCity=$$v},expression:"apoCity"}},'v-select',_vm.$attrs,false)):_c('v-text-field',_vm._b({ref:"city",attrs:{"rules":[
                                        _vm.formRules.required,
                                        _vm.formRules.maxLength(
                                            35,
                                            'characters'
                                        )
                                    ],"dense":"","outlined":"","label":"City"},model:{value:(_vm.userStoredAddress.city),callback:function ($$v) {_vm.$set(_vm.userStoredAddress, "city", $$v)},expression:"userStoredAddress.city"}},'v-text-field',_vm.$attrs,false))],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',_vm._b({ref:"zip",attrs:{"dense":"","outlined":"","rules":[
                                        _vm.formRules.required,
                                        _vm.formRules.maxLength(
                                            35,
                                            'characters'
                                        )
                                    ],"label":"ZIP / Postal Code"},model:{value:(_vm.userStoredAddress.postalCode),callback:function ($$v) {_vm.$set(_vm.userStoredAddress, "postalCode", $$v)},expression:"userStoredAddress.postalCode"}},'v-text-field',_vm.$attrs,false))],1)],1)],1)],1)],1)],1)]),_c('div',{staticClass:"container-bg",staticStyle:{"display":"block","margin":"auto","margin-top":"0px","margin-left":"0px"}},[_c('v-overlay',{attrs:{"absolute":true,"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('div',{staticClass:"container-fg"},[_c('h1',{staticStyle:{"font-size":"36px","letter-spacing":"-0.08rem"}},[_vm._v(" Your Order ")]),_c('p',[_vm._v("Please confirm your order or "),_c('a',{on:{"click":function($event){return _vm.$emit('backToCart')}}},[_vm._v("go back to your cart")]),_vm._v(".")]),_c('v-divider',{staticStyle:{"margin-top":"-10px","margin-bottom":"15px","border-color":"gray"}}),_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"id":"request-data-table","dark":"","headers":_vm.headers,"hide-default-footer":"","disable-sort":"","items":_vm.cart,"loading-text":"Loading...","no-data-text":"No items in cart"},scopedSlots:_vm._u([{key:"item.partDescription",fn:function(ref){
                                    var item = ref.item;
return [_c('span',{staticStyle:{"color":"#db5120","font-size":"10px"}},[_vm._v(_vm._s(item.category))]),_c('br'),_vm._v(" "+_vm._s(item.partDescription)+" ")]}}])}),_c('v-divider',{staticStyle:{"margin-bottom":"1%","border-color":"rgb(56, 56, 56)","width":"90%"}}),_c('v-btn',{staticStyle:{"display":"block","margin":"auto","margin-top":"20px"},attrs:{"color":"primary","elevation":"0","disabled":_vm.$apollo.loading},on:{"click":_vm.createOrder}},[_vm._v(" Create Order ")])],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }